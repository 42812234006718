import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUserType } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = useMemo(() => {
    const data = [];

    if (currentUserType === 'gamevertised-streamer') {
      data.push({
        text: <FormattedMessage id="UserNav.yourListings" />,
        selected: currentPage === 'ManageListingsPage',
        linkProps: {
          name: 'ManageListingsPage',
        },
      });
    }

    data.push(
      {
        text: <FormattedMessage id="UserNav.profileSettings" />,
        selected: currentPage === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="UserNav.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      }
    );

    return data;
  }, [currentPage, currentUserType]);

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  currentUserType: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
  currentUserType: string,
};

export default UserNav;
